






























import { Component, Vue, Watch } from 'vue-property-decorator'
import { TAX_RATE } from '@/store/Purchase'
import config from '@/config'
import contractStore, { Contract } from '@/store/Contracts'
import VABox from 'va/widgets/VABox.vue'
import dayjs from '@/libs/dayjs'

@Component({ components: { VABox } })
export default class extends Vue {
  form: HTMLFormElement | null = null
  taxRate = TAX_RATE
  error = ''
  sbp = {
    merchantId: config.purchase.sbp.merchantId,
    serviceId: config.purchase.sbp.serviceId,
    payType: '1', //継続課金（簡易）
    autoChargeType: '1', //自動課金する
    serviceType: '1', //解約
    divSettele: '0', //前払い
    campType: '0', //キャンペーンなし
    terminalType: '0', //PC
    successUrl: config.purchase.sbp.change.createUrl,
    cancelUrl: config.purchase.sbp.change.cancelUrl,
    errorUrl: config.purchase.sbp.change.errorUrl,
    pageconUrl: config.purchase.sbp.change.cancelPageconUrl,
  }

  @Watch('$route')
  mounted(): void {
    this.form = document.forms.namedItem('cancelForm')
  }

  get contract(): Contract | null {
    const latestContract = contractStore.latestContract
    const futureContract = contractStore.futureContract
    return futureContract ?? latestContract
  }

  get tax(): number {
    return this.taxExcludedPrice * this.taxRate
  }

  get taxIncludedPrice(): number {
    return this.taxExcludedPrice + this.tax
  }

  get taxExcludedPrice(): number {
    return this.contract?.plan?.price ?? 0
  }

  async cancelRequest(): Promise<void> {
    try {
      if (this.form === null) {
        throw 'formが設定されていません。'
      }

      this.form.sps_hashcode.value = await this.getHash()
      this.form.action = config.purchase.sbp.requestUrl
      this.form.submit()
    } catch (e) {
      this.error = '解約処理に失敗しました。'
      console.error(e)
    }
  }

  private async getHash(): Promise<string> {
    const encoder = new TextEncoder()
    const hashSource = this.getHashSource()

    const encodedSource = encoder.encode(hashSource)
    const hashBuffer = await crypto.subtle.digest('SHA-1', encodedSource)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  }

  private getHashSource(): string {
    if (this.form === null) {
      throw 'formが設定されていません。'
    }

    this.form.pay_method.value = 'credit'
    this.form.item_id.value = this.contract?.plan?.itemId
    this.form.item_name.value = this.contract?.plan?.name
    this.form.tax.value = this.tax
    this.form.amount.value = this.taxIncludedPrice
    this.form.cust_code.value = this.contract?.organizationId
    this.form.order_id.value = this.contract?.id + '_c'
    this.form.request_date.value = dayjs().format('YYYYMMDDHHmmss')
    this.form.tracking_id.value = this.contract?.trackingId

    return [
      this.form.pay_method.value,
      this.form.merchant_id.value,
      this.form.service_id.value,
      this.form.cust_code.value,
      this.form.order_id.value,
      this.form.item_id.value,
      this.form.item_name.value,
      this.form.tax.value,
      this.form.amount.value,
      this.form.pay_type.value,
      this.form.auto_charge_type.value,
      this.form.service_type.value,
      this.form.div_settele.value,
      this.form.camp_type.value,
      this.form.tracking_id.value,
      this.form.terminal_type.value,
      this.form.success_url.value,
      this.form.cancel_url.value,
      this.form.error_url.value,
      this.form.pagecon_url.value,
      this.form.request_date.value,
      config.purchase.sbp.hashKey,
    ].join('')
  }

  move(): void {
    this.$router.push({ name: 'PurchaseContractChangeSelectPlan' })
  }
}
