




































import { Component, Vue, Watch } from 'vue-property-decorator'
import menuStore from '@/store/Menu'
import contractStore, { Contract, ContractType } from '@/store/Contracts'
import { TAX_RATE } from '@/store/Purchase'
import Form from '@/components/organisms/Purchase/Contract/Change/CancelForm.vue'
import VABox from 'va/widgets/VABox.vue'
import dayjs from '@/libs/dayjs'
import StepBar from '@/components/organisms/Purchase/Contract/Change/StepBar.vue'

@Component({ components: { VABox, Form, StepBar } })
export default class extends Vue {
  contractChangeInfo: Record<string, string> = {}
  taxRate = TAX_RATE
  @Watch('$route')
  async mounted(): Promise<void> {
    menuStore.setActiveFlg({ settings: true })
    try {
      const contractChangeInfo = sessionStorage.getItem('ContractChangeInfo')
      if (!contractChangeInfo) throw new Error()
      this.contractChangeInfo = JSON.parse(contractChangeInfo)

      await Promise.all([contractStore.fetchLatestContract(), contractStore.fetchFutureContract()])
      if (!this.isPossibleChangeContract(contractStore.latestContract, contractStore.futureContract)) {
        throw new Error()
      }
    } catch {
      this.$router.replace({ name: 'Contract' })
    }
  }

  isCredit(contract: Contract | null): boolean {
    return contract?.type === ContractType.CREDIT
  }

  isNegotiation(contract: Contract | null): boolean {
    return contract?.type === ContractType.NEGOTIATION
  }

  isCanceled(contract: Contract | null): boolean {
    return !!contract?.endDate
  }

  /**
   * 現在がクレジット契約で解約済みでは無いかつ、翌月以降が相対ではない\
   * または、翌月以降がクレジット契約\
   */
  isPossibleChangeContract(latestContract: Contract | null, futureContract: Contract | null): boolean {
    return (
      (this.isCredit(latestContract) && !this.isCanceled(latestContract) && !this.isNegotiation(futureContract)) ||
      this.isCredit(futureContract)
    )
  }

  get contract(): Contract | null {
    return contractStore.futureContract ?? contractStore.latestContract
  }

  dateFormat(dateStr?: string): string {
    return dateStr ? dayjs(dateStr).format('YYYY/MM/DD') : ''
  }

  get startDate(): string {
    return this.dateFormat(this.contract?.startDate)
  }

  get endDate(): string {
    return this.dateFormat(this.contract?.endDate)
  }

  get maxGroups(): number {
    return this.contract?.plan?.maxGroups ?? 0
  }

  get tax(): number {
    return this.taxExcludedPrice * this.taxRate
  }

  get taxIncludedPrice(): number {
    return this.taxExcludedPrice + this.tax
  }

  get taxExcludedPrice(): number {
    return this.contract?.plan?.price ?? 0
  }

  get changeMaxGroups(): number {
    return Number(this.contractChangeInfo.maxGroups)
  }

  get changeTaxIncludedPrice(): number {
    return Number(this.contractChangeInfo.amount)
  }

  get changeStartDate(): string {
    return dayjs().add(1, 'month').startOf('month').format('YYYY/MM/DD')
  }
}
